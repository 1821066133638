<template>
	<div>
		<support-dialog
			v-model="supportDialog"
			:full-path="`${apiUrl}${$route.fullPath}`"
			@closeDialog="supportDialog = false"
		/>

		<logout-dialog v-model="logoutDialog" />

		<v-navigation-drawer
			v-if="connected && profile.clinic"
			v-model="drawer"
			dark
			color="secondary"
			app
			permanent
			:temporary="!mini"
			expand-on-hover
			:mini-variant.sync="mini"
		>
			<div class="full-height d-flex flex-column">
				<v-list>
					<v-list-item>
						<v-list-item-icon class="mr-3">
							<v-img max-width="24" src="@/assets/img/icon-fresk-dark.svg" />
						</v-list-item-icon>

						<v-list-item-content class="d-flex">
							<v-list-item-title v-if="!mini">
								<v-img max-width="100" src="@/assets/img/logo-white.svg" class="ml-3" />
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-divider />

				<navigation-list
					:items="filterBySector(items.top)"
					class="flex-grow-1"
					:notification="hasPendingNotifications"
				/>

				<v-divider />

				<navigation-list
					:items="filterBySector(items.bottom)"
					@click:help="supportDialog = true"
					@click:logout="logoutUser"
				/>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

import { prodMode } from "@/common/utils";
import { CONSULTATION_WHITELIST } from "@/common/const";
import { version } from "@/main";
import { API_URL } from "@/App.vue";

import SupportDialog from "../support/SupportDialog";
import LogoutDialog from "../login/LogoutDialog";

import NavigationList from "./NavigationList.vue";

export default {
	name: "NavigationMenu",
	components: { SupportDialog, LogoutDialog, NavigationList },

	data() {
		return {
			drawer: true,
			mini: true,
			supportDialog: false,
			logoutDialog: false,
			oldActiveType: undefined,
			version,
			apiUrl: API_URL,
			items: {
				top: [{
					id: "conversations",
					title: "conversations",
					icon: "mdi-message-text-outline",
					route: "/conversations"
				}, {
					id: "text-bloc",
					title: "quick-answers",
					icon: "mdi-message-plus-outline",
					route: "/text-bloc"
				}, {
					id: "library",
					title: "libraries",
					icon: "mdi-notebook-outline",
					route: "/material/2"
				}, {
					id: "consultations",
					title: "consultations",
					icon: "mdi-clipboard-check-outline",
					route: "/consultations",
					disabled: () => !CONSULTATION_WHITELIST.includes(this.profile.clinic)
				}, {
					id: "contacts",
					title: "contacts",
					icon: "mdi-account-group",
					route: "/contacts"
				}, {
					id: "configuration",
					title: "configuration",
					icon: "mdi-cog-outline",
					route: "/configuration"
				}],
				bottom: [{
					id: "devops",
					title: "devops",
					icon: "mdi-wrench",
					route: "/devops",
					visibilityGuard: () => this.isDevops
				}, {
					id: "help",
					title: "help",
					icon: "mdi-help-circle-outline"
				}, {
					id: "version",
					title: "version",
					titleParams: { version },
					subtitle: !prodMode() ? process.env.NODE_ENV : undefined,
					icon: "mdi-animation-outline",
					route: "/release-notes",
					visibilityGuard: () => !this.isFrance
				}, {
					id: "logout",
					title: "logout",
					icon: "mdi-logout"
				}]
			}
		};
	},

	computed: {
		...mapGetters("login", ["connected", "profile", "isDevops"]),

		...mapGetters("conversation", [
			"singleConversation",
			"isInitializing",
			"hasPendingNotifications"
		]),

		...mapFields("blocGrid", ["activePostType", "loadingPosts"]),
		...mapGetters("clinic", ["isSector", "clinicData", "hasWebsite", "isFrance"])
	},

	watch: {
		mini() {
			this.$emit(this.mini ? "mini" : "expanded");
		}
	},

	methods: {
		async logoutUser() {
			this.logoutDialog = true;
		},

		filterBySector(menuItems) {
			if (!Array.isArray(menuItems)) return menuItems;

			return menuItems.filter((menuItem) => {
				return (
					!menuItem.visibilityGuard ||
					(typeof menuItem.visibilityGuard == "function" && menuItem.visibilityGuard())
				);
			});
		},

		changeNavigation(newLink) {
			if (this.oldActiveType !== undefined && this.oldActiveType != newLink) {
				this.loadingPosts = true;
				this.disableNavigation(newLink);
			}

			this.oldActiveType = newLink;
		}
	}
};
</script>
